import en from './en';

import cn from './cn';

export default {

  en: en,

  cn: cn,

}