<template>
<el-container>
    <el-header style="padding: 0px;">
      <header>

      </header>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <side-bar />
      </el-aside>
      <el-main>
        <transition name="page-transition">
          <router-view />
        </transition>
        <!-- <img id="logo" src="~@/assets/logo.png" alt="electron-vue" />
          <main>
            <div class="left-side">
              <div v-for="(client, index) in clients" :key="index">{{client}}</div>
            </div>

            <div class="right-side">
              <el-button type="primary">Add new client</el-button>
              <el-button type="primary" @click="$router.push('/new_order')">Add new order</el-button>
            </div>
        </main>-->
      </el-main>
      <!-- <el-footer>Footer</el-footer> -->
    </el-container>
  </el-container>
</template>

<script>
// @ is an alias to /src
import SideBar from '@/components/SideBar'

export default {
  name: 'Home',
  components: {
    SideBar
  }
}
</script>
