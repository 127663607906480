import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import i18n from './i18n/i18n'

import axios from 'axios'

// [vxe表格] 表格插件
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'


Vue.config.productionTip = false

Vue.prototype.$store = store;
Vue.prototype.$axios = axios;


VXETable.use(VXETablePluginElement)
Vue.use(VXETable)

import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);

import util from '@/libs/util.js'
const whiteList = ['/login', '/about', '/buy', '/pay-success']
let count = 0

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  // 进度条
  if (whiteList.includes(to.path)) {
    // 在免登录白名单，直接进入
    next()

  } else {
    // 这里暂时将cookie里是否存有token作为验证是否登录的条件
    // 请根据自身业务需要修改
    const token = util.cookies.get('Mema-Token')
    const hasToken = token && token !== 'undefined'
    // 根据routers的值判断动态的菜单是否已经加载完成

    if (hasToken) {
      try {
        next();
      } catch (err) {
        next({ path: '/login'})
        count = 0
      }
    } else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next({ path: '/login' })
 
      count = 0
    }
  }
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
