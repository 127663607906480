import { Message, MessageBox } from 'element-ui'
import util from '@/libs/util.js'
import router from '@/router'
import store from '@/store/index'
import { sysAccountService } from '@/api'
import i18n from '@/i18n/i18n.js'

export default {
  namespaced: true,
  actions: {
    /**
     * @description 登录
     * @param {Object} param context
     * @param {Object} param username {String}
     * @param {Object} param password {String} 密码
     * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login ({ dispatch }, {
      username = '',
      password = ''
    } = {}) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        sysAccountService.login({
          username,
          password
        })
          .then(async res => {
            // 先清除用户信息
            await dispatch('clear')

            // 设置 cookie 一定要存 uuid 和 token 两个 cookie
            // 整个系统依赖这两个数据进行校验和存储
            // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
            // token 代表用户当前登录状态 建议在网络请求中携带 token
            // 如有必要 token 需要定时更新，默认保存一天
            util.cookies.set('uuid', res.uuid)
            util.cookies.set('Mema-Token', res.token, { expires: res.expire })
            debugger
            // 设置 vuex 用户信息
            await dispatch('d2admin/user/set', {
              name: res.username,
              superUser: res.superUser
            }, { root: true })
            // 用户登录后从持久化数据加载一系列的设置
            await dispatch('load')
            // 结束
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} param context
     * @param {Object} param confirm {Boolean} 是否需要确认
     */
    logout ({ commit, dispatch }, { confirm = false, msg } = {}) {
      /**
       * @description 注销
       */
      async function logout () {
        // 先清除用户信息
        await dispatch('clear')
        // 跳转路由
        router.push({
          name: 'login'
        })
      }
      // 判断是否需要确认
      if (confirm) {
        commit('d2admin/gray/set', true, { root: true })
        MessageBox.confirm(i18n.t('public.confirm.special.logout.message'), i18n.t('public.confirm.special.logout.title'), {
          confirmButtonText: i18n.t('public.confirm.special.logout.button.confirm'),
          cancelButtonText: i18n.t('public.confirm.special.logout.button.cancel'),
          type: 'warning'
        })
          .then(() => {
            commit('d2admin/gray/set', false, { root: true })
            logout()
          })
          .catch(() => {
            commit('d2admin/gray/set', false, { root: true })
            Message({
              message: i18n.t('public.message.special.logout.cancel')
            })
          })
      } else {
        if(msg) {
          Message({
            message:msg,
            type: 'error'
          })
        }
        logout()
      }
    },
    async clear({ dispatch }) {
      console.log('----------清空缓存----------')
      // 删除cookie
      util.cookies.remove('Mema-Token')
      util.cookies.remove('uuid')
      // 清空 vuex 菜单信息
      util.session.clear()
      //store.commit('d2admin/permission/SET_ISLOCK', false)
      // 清空 vuex 用户信息
      await dispatch('d2admin/user/set', {}, { root: true })
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} state vuex state
     */
    load ({ dispatch }) {
      return new Promise(async resolve => {
        // DB -> store 加载用户名
        await dispatch('d2admin/user/load', null, { root: true })
        // DB -> store 加载主题
        await dispatch('d2admin/theme/load', null, { root: true })
        // DB -> store 加载页面过渡效果设置
        await dispatch('d2admin/transition/load', null, { root: true })
        // DB -> store 持久化数据加载上次退出时的多页列表
        await dispatch('d2admin/page/openedLoad', null, { root: true })
        // DB -> store 持久化数据加载侧边栏折叠状态
        await dispatch('d2admin/menu/asideCollapseLoad', null, { root: true })
        // DB -> store 持久化数据加载全局尺寸
        await dispatch('d2admin/size/load', null, { root: true })
        // DB -> store 持久化数据加载颜色设置
        await dispatch('d2admin/color/load', null, { root: true })
        // end
        resolve()
      })
    }
  }
}
