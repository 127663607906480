import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/admin',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'slider',
        name: 'manage_slider',
        component: () => import('@/views/slider.vue')
      },
      {
        path: 'restaurantSlider',
        name: 'manage_rest_slider',
        component: () => import('@/views/restaurantSlider.vue')
      },
      {
        path: 'activity',
        name: 'manage_activity',
        component: () => import('@/views/activity.vue')
      },
      {
        path: 'alumniActivity',
        name: 'manage_alumni_activity',
        component: () => import('@/views/AlumniActivity.vue')
      },
      {
        path: 'sendAlumniInv',
        name: 'sendAlumniInv',
        component: () => import('@/views/sendAlumniInv.vue')
      },
      {
        path: 'restaurant',
        name: 'manage_restaurant',
        component: () => import('@/views/restaurant.vue')
      },
      {
        path: 'usersToVerify',
        name: 'users_to_verify',
        component: () => import('@/views/UsersToVerify.vue')
      },
      {
        path: 'alumniToVerify',
        name: 'alumni_to_verify',
        component: () => import('@/views/AlumniToVerify.vue')
      },
      {
        path: 'user',
        name: 'manage_user',
        component: () => import('@/views/user.vue')
      },
      {
        path: 'alumni',
        name: 'manage_alumni',
        component: () => import('@/views/alumni.vue')
      },
      {
        path: 'activity_detail',
        name: 'activity_detail',
        component: () => import('@/views/activity-detail.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/buy',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BuyCard.vue')
  },
  {
    path: '/pay-success',
    name: 'pay-success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PaymentSuccess.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  
]

const router = new VueRouter({
  routes
})

export default router
