import enLocale from 'element-ui/lib/locale/lang/en'

const en = {

    ...enLocale,

    Login: {

        'title': 'Backstage Management',
        'login': 'Login',
        'register': 'Register',
        'captcha': "Captcha",
    },

    Home: {
        'admin': 'Admin',
        'profile': 'Profile',
        'girls': 'Girls',
        'roster': 'Roster',
        'list': 'List',
        'settings': 'Settings',
        'upload': 'Upload',
        'please_input_shop': 'Please input shop name',
        'shop': 'Shop',
        'address': 'Address',
        'phone': 'Phone',
        'description': 'Description',
        'name': 'Title',
        'delete': 'Delete',
        'edit': 'Edit',
    },

    Common: {
        'ok': 'OK',
        'cancel': 'Cancel',
    }

}

export default en;