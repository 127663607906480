import zhLocale from "element-ui/lib/locale/lang/zh-CN";

// 用于存放 语言字段

const cn = {
  ...zhLocale,

  // 登录页面

  Login: {
    title: "后台管理",
    login: "Login",
    register: "Register",
    captcha: "Captcha",
  },

  Home: {
    admin: "Admin",
    profile: "信息",
    girls: "所有女孩",
    roster: "名单管理",
    list: "列表",
    settings: "设置",
    upload: "上传",
    please_input_shop: "Please input shop name",
    shop: "店名",
    address: "地址",
    phone: "电话",
    description: "描述",
    name: "名字",
    delete: "删除",
    edit: "编辑",
  },

  Common: {
    ok: "确定",
    cancel: "取消",
  },
  views: {
    public: {
      success: "成功",
      confirm: "确定",
      cancel: "取消",
    },
  },
};

export default cn;
